import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

import { NavBar } from "../components/NavBar";
import { WhyContent } from "../components/WhyContent";
import { Type } from "../components/Type";
import PoliciesPage from "./policies";
import Footer from "./footer";
import MailingList from "../components/MailingList/MailingList";
import NewsPage from "./news";
import Endorsements from "./endorsements";

import "../styles/global.scss";
import "./index.scss";
import "../styles/svg.scss";

const MAIN_WORDS = [
  { word: "equitable." },
  { word: "diverse." },
  { word: "innovative." },
  { word: "inclusive." },
  { word: "community-driven.", toDelete: false },
  { word: "accountable." },
];

// markup
const IndexPage = () => {
  const [openPopup, setOpenPopup] = useState(true);

  return (
    <main>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Dana For Cambridge</title>
      </Helmet>
      <NavBar />
      <section>
        <div className="main-wrapper">
          <div className="imgbox">
            <StaticImage
              src="../images/me-in-central_zoomish.jpeg"
              alt="trees near charles"
              className="center-fit"
              placeholder="blurred"
            />
          </div>
          <div className="image-overlay">
            <div className="focus-text">
              Let's make Cambridge <Type words={MAIN_WORDS} />
            </div>
          </div>
        </div>
      </section>
      <WhyContent />
      <div className="endorsements-wrapper">
        <Endorsements />
      </div>
      <div className="pictureBreak--wrapper">
        <StaticImage
          src="../images/trees-near-charles.jpg"
          placeholder="blurred"
          alt="Trees in Charles River"
          className="pictureBreak-charles"
        />
      </div>

      <div className="policies-page-wrapper">
        <PoliciesPage />
      </div>
      <div className="news-page-wrapper">
        <NewsPage />
      </div>
      <MailingList />
      <Footer />
      <div className={`joinPopup ${openPopup ? "" : "joinPopup--close"}`}>
        <div
          role="banner"
          className="joinPopup--x"
          onClick={() => setOpenPopup(false)}
        >
          &#10006;
        </div>
        <p style={{ fontSize: "1.3em" }}>
          Donate to Dana's Campaign {""}
          <Link to="https://secure.actblue.com/donate/dana-bullister-1?commit=Contribute">here!</Link>
        </p>
      </div>
    </main>
  );
};

export default IndexPage;
