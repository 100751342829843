import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";

export const NEWS = [
  {
    id: "article",
    preview: (
      <div className="articleGrid">
        <StaticImage
          src="../images/cambridge-day.jpg"
          alt="dana smiling"
          className="articleImg"
        />
        <h4>
          Data scientist Bullister announces for council, planning to channel
          insights into local policy
        </h4>
        <span className="article-content">
          The first City Council challenger new to the political scene has
          declared officially for the Nov. 2 municipal elections: data scientist
          Dana Bullister.
        </span>
        <br></br>
        <span>
          She follows into the field two challengers prominent in the local
          business community, including one who has run for a council seat
          before; and several of the sitting councillors.
        </span>
        <br />
        <a
          className="read-more"
          href="https://www.cambridgeday.com/2021/05/18/data-scientist-bullister-announces-for-council-planning-to-channel-insights-into-local-policy/"
        >
          Read More
        </a>
      </div>
    ),
  },
];
