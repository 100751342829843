import React from "react";
import { Link } from "gatsby";

import "./styles/MailingList.scss";

const MailingList = () => {
  return (
    <div className="mailingList--background">
      <div className="mailingList--wrapper" id="joinmailinglist">
        <span id="volunteer">
          <h2 className="mailingList--title">Join Mailing List</h2>{" "}
          <div className="donate">
            <Link className="donate--button" to="https://secure.actblue.com/donate/dana-bullister-1?commit=Contribute">
              DONATE
            </Link>
          </div>
        </span>
        <div className="joinmailinglist--wrapper">
          <iframe
            title="Join Mailing List"
            src="https://docs.google.com/forms/d/e/1FAIpQLScS-Sv69DFEStv0yNnQJg1m7LoeJ9wya7xGpjR9_5dVxn-98w/viewform?embedded=true"
            className="joinmailinglist--frame"
          >
            Volunteer and Join Mailing List
          </iframe>
        </div>
        {/* <div className="mailingList--images">
        <div id="peopleInKendall">
          <StaticImage
            src="../../images/people_in_kendall.jpeg"
            alt="People In Kendall"
            placeholder="blurred"
            width={300}
            id="peopleInKendall"
          />
        </div>
        <div id="voted">
          <StaticImage
            src="../../images/voted.jpeg"
            alt="Voted"
            placeholder="blurred"
            width={200}
            id="voted"
          />
        </div>
        <div id="rally">
          <StaticImage
            src="../../images/rally.jpeg"
            alt="Rally"
            placeholder="blurred"
            width={350}
          />
        </div>
      </div> */}
      </div>
    </div>
  );
};

export default MailingList;
