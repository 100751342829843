import React from "react";
import Typist from "react-typist";
import TypistLoop from "react-typist-loop";

const Type = (props) => {
  const { words = [] } = props;

  return (
    <TypistLoop interval={1000}>
      {words.map(({ word }) => (
        <Typist key={word} startDelay={1000}>
          {word}
          <Typist.Backspace count={word.length + 1} delay={1000} />
        </Typist>
      ))}
    </TypistLoop>
  );
};

export default Type;
