import React from "react";

import "./footer.scss";

const Footer = () => {
  return (
    <div id="footer">
      <footer>
        <div className="votingInfo">
          <div className="contactInfo">
            <p>Committee to Elect Dana Bullister</p>
            <p>Vote #1 Tuesday, November 2nd</p>
            <p>Cambridge City Council</p>
            <p>(774) 270-1385</p>
            <p>dana@danaforcambridge.com</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
