import * as React from "react";
import { NEWS } from "../constants/news";

import "./news.scss";

const NewsPage = () => {
  return (
    <div>
      <div className="main-content">
        <section className="main-section">
          <div className="content-heading">
            <h2 id="news" className="news-heading">
              Recent News
            </h2>
          </div>
        </section>
      </div>
      <div>
        <section className="content">
          <div className = "articles-wrapper">
          <div className="articles">
            {NEWS.map(({ id, preview }, index) => {
              return (
                <article id={`${id}${index}`} key={index}>
                  {preview}
                </article>
              );
            })}
          </div>
          <a
            className="twitter-timeline"
            data-width="500"
            data-height="600"
            data-theme="dark"
            href="https://www.twitter.com/DanaBullister?ref_src=twsrc%5Etfw"
          >
            Tweets by DanaBullister
          </a>{" "}
          </div>
        </section>
      </div>
    </div>
  );
};
export default NewsPage;
