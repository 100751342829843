import * as React from "react";
import { Link } from "gatsby";

import "./endorsements.scss";
import CBSLogo from "../images/endorsements/CBS_logo.png";
import RunForSomething from '../images/endorsements/RunForSomething.png'
import MITDemocrats from '../images/endorsements/MIT_Democrats.jpg'
import PolicyPledgeLogo from '../images/endorsements/PolicyPledgeLogo.png'
import SunriseCambridge from '../images/endorsements/SunriseCambridge.png'


const ENDORSEMENTS = [{
  to: "https://twitter.com/CallaWalsh/status/1409963532301574147/photo/4",
  logo: SunriseCambridge,
  alt: "Sunrise Movement Logo",
}, {
  to: "https://runforsomething.net/",
  logo: RunForSomething,
  alt: "Run For Something",
}, {
  to: "https://democrats.mit.edu/2021-primary-endorsements/?fbclid=IwAR3YQzXO-6oWop1Yte4gUJIv0czva_x4I41s7rX7H-TIkDQPZ--ZrqBHdTc",
  logo: MITDemocrats,
  alt: "MIT Democrats",
},
{
  to: "https://twitter.com/prochoicemass/status/1440724035310997504",
  logo: PolicyPledgeLogo,
  alt: "Policy Pledge",
}, {
  to: "https://www.cambridgebikesafety.org/",
  logo: CBSLogo,
  alt: "Cambridge Bicycle Safety Logo",
}]


const Endorsements = () => {
  return (
    <>
      <div className="endorseBlock">
        <h1 className="endorseBy">Endorsed By</h1>
      </div>
      <div className="endorsementPage">
        <div className="endorsements">
          {ENDORSEMENTS.map(({ to, logo, alt, figcaption }) => <Link
            className="endorsementLogo"
            to={to}
          >
            <img
              src={logo}
              placeholder="blurred"
              alt={alt}
              className="endorsementImage"
            >
            </img>
            {figcaption && <figcaption style={{ color: "#344380" }}>
              {figcaption}
            </figcaption>}
          </Link>)}
        </div>
      </div>
    </>
  );
};
export default Endorsements;
